import React from 'react';

function Background() {
  return (
    <>
      <div className='padding-4 backgroung-cont'>
        <p>
          Africa is the most promising and gifted content of Africa. With a population of 1.25 billion, two-thirds of which is less than 35, Africa possesses the biggest development and growth potential in the world.
          <br />The challenges to harness the growth potential are enormous. It needs political will, structured and forward-looking policies and a robust work plan. The African Union and the African member states have worked, deliberated and created a vision for 2063 which gives the policy direction and work plan to actualize the full potential of the African continent.
        </p>

        <p>
          The African countries need to prioritize focus on the core sector's agriculture, infrastructure, training, skill development, education, electricity, good medical and health facilities to boost their economies. The focus needs investment.It is here that African countries are in helpless situation. The only way to bring the necessary financial resources is either let the natural resources be exploited and exported or to borrow money, i.e. bring finance from international financial partners.
        </p>
        <p>
          To develop the core sectors, African Governments have tried to get the finance raised, many a time on unfair terms, and get some middle and large scale projects set up. The African States had hoped that with borrowed money, these industrial projects will provide the technology, skill development, jobs and accrue benefits for the population.
        </p>
        <p>
          This model though has not been successful. Many projects set up by the State have failed because the feasibility of the projects was miscounted and the Governments do not have the competence, money and human resources to operate these projects and generate business. So, many projects have become dysfunctional and Governments are finding themselves in the lurch as while the projects have not generated any benefits for the country, the Governments have to bear the financial burden to service the loan and have unfortunately come under a debt trap.
        </p>
        <p>
          Further and therefore, the International Monitory Fund (IMF) has issued directives to the African countries not allowing them to borrow more.
        </p>
        <p>
          The only solution in this imbroglio is to bring private investment in the PPP (Public Private Partnership) model in Africa.
        </p>
        <p>Private investment is no cost to the Exchequer and positively affects the GDP growth of the country. A private investor, with his own money, ensures the most correct and best feasibility for the project and therefore it is a fact that private investment businesses and projects are most successful.</p>
        <p>The African economies still rely, over 95%, on the unorganized and informal sector and very small economic activity is attributed to the organized sector. The Governments therefore need to focus on micro, small and medium enterprises in the core sectors to boost economic development. Private investment, therefore has to be encouraged in the bottom-up approach while also for important industrialization projects in the core sectors.</p>
        <p>FICA (Federation of International Consular Chambers of Africa) is founded on this very premise with the sole motto to encourage private investment in PPP model in Africa.</p>
        <p>FICA and its partners have been undertaking lot of work to achieve the objectives to capacity building, skill development and sustained economic development for the African Continent. There has been a lot of progress leading to tangible results through these sustained efforts in the last many years.</p>
        <p>FICA brings a new concept, a new approach and a new intervention for achieving the goals of sustained economic development for Africa by encouraging and boosting private investment.</p>
        <p>To foster international investment, there is a strong need to develop the local industry, local chambers and the banking. FICA therefore works as a focal point to involve the Governments, local industry and banks for creation of best partnerships with international partners.</p>
      </div>

      {/* <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 300,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        backgroundColor: '#E9F6FD'}}
        className='padding-4'>
        <p style={{fontWeight: '500', padding: '2rem'}}>
          One of the core objectives of FICA is to create an Investment Bank in Africa to boost international investment. The investors look at the basics of every country and its economy in terms of :
        </p>
        <ul>
          <li>capacity Development</li>
          <li>skill development training</li>
          <li>corporate governance</li>
          <li>banking</li>
          <li>local chambers of industry</li>
        </ul>
        <p style={{paddingLeft: '2rem'}}>FICA will compliment these efforts by focusing in (v) and (vi) objectives for PPP.</p>
      </div> */}
      <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 600,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        backgroundColor: '#E9F6FD',
        padding: '2rem'}}
        className='padding-4 padding-4-sm'>
        <p style={{fontWeight: '600'}}>One of the core objectives of FICA is to create an Investment Bank in Africa to boost international investment. The investors look at the basics of every country and its economy in terms of:</p>

        <ul >
          <li>Capacity Development</li>
          <li>Skill development training</li>
          <li>Corporate governance</li>
          <li>Banking</li>
          <li>Local chambers of industry</li>
        </ul>
      </div>
      
      <div className='padding-4' 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 300,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px'}}>
        <h2>1. Overall Objective</h2>
        <p>The course is designed to equip trainees with theories and principles of Association/Corporate Governance; the role of stakeholders in Governance; share the current situation of Governance in organizations, and understand best practices and failures in governance. In addition, trainees will possess skills and knowledge on pertinent issues of changing and transforming essential organizational elements for the achievement of long-term goals through visionary leadership. The training also introduces decision-making and performance management skills as key elements of governance. Overall the training will enable participants to execute their roles & responsibilities properly and diligently while ensuring operations run smoothly within an organization.</p>

        <h2>2. Approach</h2>
        <p>FICA and AU Department of Partnership shall enter into an understanding, whereby :</p>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '1rem'}}>
          <li>FICA shall be a technical financial and resource partner of the Department of Partnership of AU</li>
          <li>FICA shall be the economic diplomacy partner of the AU</li>
          <li>FICA and AU, Department of Partnership, shall undertake joint missions to AU member countries</li>
          <li>FICA will enter into a framework agreement with African Governments to create a joint mechanism to achieve the agenda of economic development through PPP</li>
          <li>FICA shall create, working with African countries, a database of viable and bankable projects (small, medium and high scale) on which the work has already been done by the governments and African Union along with its partners like ACBF, NEPAD, BAD and others.</li>
        </ul>

        <h2>3. Scope Of Cooperation</h2>
        <div style={{fontWeight: '500', paddingLeft: '2rem'}} className='line-height'>The scope of cooperation between FICA and African Union shall include the following:</div>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '2rem'}}>
          <li>Collaboration for economic diplomacy to achieve the objectives of sustainable economic development through Public Private Partnership (PPP)</li>
          <li>Collaboration to set up an Investment Bank for Africa.</li>
          <li>Mobilize the investment resources to support the African Union Department of Partnership</li>
          <li>Mobilizing technical and human resources to support African Union Department of Partnership</li>
          <li>Mobilize the technical and financial resources to create a training center for capacity building in Africa</li>
          <li>Develop the framework line for the “PPP” Public Private Partnership projects in Africa</li>
          <li>Promote the development of inter and regional project in Africa</li>
          <li>Direct investment via African Union projects, that the Investment Bank</li>
          <li>Implementation of financial tools & collaboration with African Countries Members for structuring investments in various projects.</li>
          <li>Create the investment platform for different projects in Africa</li>
          <li>Create the technical platform to support the projects initiative in Africa</li>
          <li>Conducting joint missions as partners to various countries in Africa and internationally</li>
          <li>Collaboration between the respective local Governors and teams of African Union and FICA in African member countries</li>
          <li>Organization of workshops, seminars, conferences and the exchange of information</li>
          <li>Any other forms of political, economic, cultural cooperation that may be jointly decided upon by the Participants</li>
        </ul>
        
        <h2>4. Working Mechanism</h2>
        <div style={{fontWeight: '500', paddingLeft: '2rem'}} className='line-height'>Based on the scope of cooperation, the AU and FICA shall have a working mechanism as follows :</div>
      </div>
      <div style={{backgroundColor: '#E9F6FD', padding: '3rem', paddingLeft: '7rem'}} className='padding-4 padding-4-sm'>
        <p>(a) Creation of a joint Adhoc Committee in the Department of Partnership of African Union</p>
        <p>(b) Mobilization of financial, technical and human resources shall be towards and for the working of the joint Adhoc Committee.</p>
        <p>(c) The Adhoc Committee to meet on a bi-weekly basis.</p>
        <p>(d) The Adhoc Committee shall have a quarterly General Meeting where the decisions on the work plan and operations shall be agreed and finalized for the joint objectives stated here between African Union and FICA.</p>
      </div>
      <div className='padding-4' 
      style={{fontSize: '1.2rem',
      lineHeight: '2.4rem',
      fontWeight: 300,
      wordSpacing: '0.4rem',
      color: '#333',
      marginBottom: '20px'}}>
        <h2>5. Partnership</h2>
        <h1 style={{color: 'black', paddingLeft: '4rem', marginTop:'1rem'}}>A WIN-WIN PARTNERSHIP TOWARDS THE AU MISSION 2063</h1>
      </div>
    </>
  );
}

export default Background;
