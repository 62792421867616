import React from 'react'

function ECCSA() {
  return (
    <>
      <div className='padding-4' 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px'}}>
        <div className='sub-title-about' style={{fontWeight: '700'}}>ECCSA needs support from the Women and Youth Business Support Promotion Division to undertake the following activities:</div>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '1rem'}}>
          <li>Support to promote knowledge-sharing programs and capacity development</li>
          <li>Support in the training programs that address the needs of the youth and women</li>
          <li>Support in the designing of coaching and mentoring programs</li>
          <li>Develop BDS Services for Youths and Women in Business</li>
          <li>Support in the development of women in Business Database</li>
          <li>Support for Youth & Women in Business for their Access to Regional & Global Business Networks, in research and development</li>
          <li>Avail Chamber Academy for Youth & Women Entrepreneurs</li>
          <li>Support annual business (youth & women) event, business Forums and Conferences</li>
          <li>Support in the development and use of web based platforms</li>
          <li>Advocate for better business environment</li>
        </ul>


        <h2>Main activities:</h2>
        <p><b>A1: </b>Development of women and youth training materials, including a set of capacity building and training modules on Agriculture, agribusiness, leather and textile industry, livestock, technical and professional training, job creation, and income generation. The modules will be developed based on approved international standards.</p>
        <p><b>A2: </b>Organize training workshops for women and network of women groups/associations and youth as well as potential trainers to sustain capacity-building activities.</p>
        <p><b>A3: </b>Establish and strengthen existing Forums of women entrepreneurs to share experiences and best practices on Agriculture, agribusiness, leather and textile industry, livestock, technical and professional training, job creation, income generation and value chain approaches.</p>
        <p><b>A4: </b>BDS Service developed for Youths and Women in Business</p>
        <p><b>A5: </b>Support in the development of women in Business Database</p>
        <p><b>A6: </b>Strengthen the leadership and management skills of the Ethiopian Business Membership Organizations</p>
        <p><b>A7: </b>Created platforms to share best practices and experiences among Ethiopian Chambers, Associations and other membership based organizations.</p>

        <h2>Main activities:</h2>
        <p>Taking into account the above, the objective of promoting the initiative of a Strategic Asset Fund for Emergence in Africa is threefold, namely:</p>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>1</span>
          <span className='fundraising-body-data'>Make the management of structuring projects consistent with the Fund's asset policy by promoting major infrastructure projects and programs for economic emergence, the development of promising sectors and the expansion of the private sector.</span>
        </div>

        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>2</span>
          <span className='fundraising-body-data'>Converge financial resources on the passive side, coordinating the administration of funds to be raised or raised, with a strategy combining different types of financing adapted to the needs of African economies.</span>
        </div>

        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>3</span>
          <span className='fundraising-body-data'>Contribute to significantly developing financial systems in Africa through the presence of a major player involved in the management of this Fund which would be structuring for the various sectors mentioned above</span>
        </div>

        <h2>Subdivision of the Fund</h2>
        <p>The Fund's major challenge is to intervene collectively for investments in structuring projects:</p>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>1</span>
          <span className='fundraising-body-data'>BOND ASSETS: Mining, Natural Resources</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>2</span>
          <span className='fundraising-body-data'>PROJECTS “PPP”</span>
          
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>3</span>
          <span className='fundraising-body-data'>FEASIBILITY STUDY</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>4</span>
          <span className='fundraising-body-data'>DEVELOPMENT OF AGRICULTURAL PRODUCTIONSs</span>
        </div>

        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>5</span>
          <span className='fundraising-body-data'>GUARANTEE ON STOCKS OF RAW MATERIALS</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>6</span>
          <span className='fundraising-body-data'>COMMON BASIS OF GOOD GOVERNANCE</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>7</span>
          <span className='fundraising-body-data'>GREEN BACKGROUND / ENVIRONMENT / CLIMATE / RESILIENCE</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>8</span>
          <span className='fundraising-body-data'>FINANCE INCLUSIVE</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>9</span>
          <span className='fundraising-body-data'>INSURANCE / MICROINSURANCE / SAVINGS</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>10</span>
          <span className='fundraising-body-data'>FINANCING INDUSTRIAL EQUIPMENT</span>
        </div>
        <div className='subheading-data eccsa'>
          <span className='fundraising-body-numbering'>11</span>
          <span className='fundraising-body-data'>FINANCIAL AND INVESTMENT RESSOURCE MOBILIZATION</span>
        </div>
        
      </div>
    </>
  )
}

export default ECCSA