import React from 'react'

function ThreeStepStrategy() {
  return (
    <div className='strategicwork-container'>
      <div className='threestep-heading'>
        <span className='threestep-step'>Step A: </span>
        <span className='threestep-data'>Recast the Value Proposition</span>
      </div>
      <div className='strategicwork-content'>
        <ul className='strategicwork-ul'>
          <li className='strategicwork-li'>Increase awareness and visibility for FICA as an integrated part of the South Souths and Triangular Cooperation for Economy Diplomacy.</li>
          <li className='strategicwork-li'>Differentiate FICA within the broader marketplace as a superior location for new business investment.</li>
          <li className='strategicwork-li'>Facilitate the location decision: make it easy for potential targets to make the investment.</li>
        </ul>
      </div>
      <div className='line'></div>

      <div className='threestep-heading'>
        <span className='threestep-step'>Step B:</span>
        <span className='threestep-data'>Maximize Lead Generation</span>
      </div>
      <div className='strategicwork-content'>
        <ul className='strategicwork-ul'>
          <li className='strategicwork-li'>Target the right sectors in the right geographies; Align focus sectors with shifting sector composition and Countries competitive advantages.</li>
          <li className='strategicwork-li'>Focus first on exports from existing firms and then Inbound FICA opportunities from outside AFRICA.</li>
          <li className='strategicwork-li'>Utilize resources efficiently and effectively.</li>
        </ul>
      </div>
      <div className='line'></div>

      <div className='threestep-heading'>
        <span className='threestep-step'>Step C:</span>
        <span className='threestep-data'>Ensuring Investment Readiness</span>
      </div>
      <div className='strategicwork-content'>
        <ul className='strategicwork-ul'>
          <li className='strategicwork-li'>Ensure a supply of shovel ready lands, particularly for large space users such as distribution, warehousing and large food processing and manufacturing activities.</li>
          <li className='strategicwork-li'>Take a proactive and integrated approach to tracking and securing investments and facilitating approvals.</li>
          <li className='strategicwork-li'>Provide strong after sales support to ensure smooth adjustment to Countries and leverage additional opportunities.</li>
        </ul>
      </div>
      <div className='line'></div>
    </div>
  )
}

export default ThreeStepStrategy