import React from 'react';
function Profiles({ title, data }) {

  const handleRedirect = (name, title) => {
    if (title === 'Board Team Members F.I.C.A.') {
      let id = name?.replace(/ /g, '-')
      window.location.href = `/board-team-member/${id}`
    }
    if (title === 'Team Profile F.I.C.A.') {
      let id = name?.replace(/ /g, '-')
      if(name!=='Gopal Ghirish' && name!=='Mr. Philip UZUMA NNGANYADI') {
        window.location.href = `/team-profile/${id}`
      } else {
        window.location.href = `/team-profile/${id}?pageNo=1`
      }
    }
  }

  return (
    <>
      <div className='profile-container'>
        <h2 className='profile-title'>{title}</h2>
      </div>
      <div className='profile-scrollable-wrapper'>
        <div className='profile-scrollable'>
          {data.map(({ id, name, position, info, image }) => (
            <div className='profile-card-container' key={id}>
              <div className='profile-card'>
                <div className='profile-image-container'><img loading='lazy' src={`${image}`} alt={name} className={`profile-image ${id==='3' ? 'desmond-ray' : id==='2' ? 'chakib' : ''}`} /></div>
                <div className='profile-info-container'>
                  <h3 className={`profile-name ${title==='Board Team Members F.I.C.A.' && 'board-profile-name'}`}>{name}</h3>
                  <h4 className={`profile-position ${title==='Board Team Members F.I.C.A.' && 'board-profile-position'}`}>
                    {(name==='Mrs. Lety Eposi Endeley' && title==='Board Team Members F.I.C.A.') ? 
                      (<>{position}<br/><span className='board-sub-position'>Non-life for International Markets at CICA RE Lome</span></>)
                    : position}
                  </h4>
                  <p className={`profile-info ${title==='Board Team Members F.I.C.A.' && 'board-profile-info'}`}>{info}</p>
                </div>
              </div>
              <p className='read-more-btn' onClick={()=>handleRedirect(name,title)}>Read to Know More</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Profiles;
