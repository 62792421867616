import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Space, Drawer } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { navLinks, headerLinks } from '../constants';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false); 
  const [active, setActive] = useState('overview');
  const [dropDownFlag, setDropDownFlag] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = {
      day: '2-digit', month: 'long', year: 'numeric', weekday: 'short',
      hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true,
      timeZone: 'UTC',
    };
    const datePart = date.toLocaleDateString('en-GB', options).replace(',', '').split(' ');

    return `${datePart[1]} ${datePart[2]} ${datePart[3]} | ${datePart[0]} | ${datePart[5]} ${datePart[6].toUpperCase()}`;
  };

  const handleClick = (id) => {
    setActive(id);
    navigate(`/`, {state: 
      {
        scrollVal: `${(id==='ourpartners' || id==='otherPartner' || id==='networkingCountry') ? 'ourpartners' : id}`,
        activeTab: `${(id==='ourpartners' || id==='otherPartner' || id==='ourpartners') ? id : undefined}`
      }});
  };

  const handleLinkClick = (link, parentId = null) => {
    setActiveLink(parentId ? parentId : link.id);
    if (link.dropdown) {
      navigate(`/${link.navigateTo}`);
    } else {
      navigate(`/${link.navigateTo}`, {state: {scrollVal: `${link.id}`}});
    }
    setDrawerOpen(false); 
  };

  const renderMenu = (items, parentId) => (
    <Menu
      items={items.map(item => ({
        key: item.id,
        label: (
          <div onClick={() => { handleLinkClick(item, parentId); setDropDownFlag({ ...dropDownFlag, [parentId]: false }) }}>
            {item.title}
          </div>
        ),
      }))}
    />
  );

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className='navbar-container'>
      <div className='nav-bar-date'>
        <p className='nav-date-text'>{formatDate(currentTime)} (UTC)</p>
      </div>
      <nav className='navbar'>
        <MenuOutlined className='menu-icon' style={{fontSize: '1.25rem', cursor: 'pointer' }} onClick={showDrawer} />
        <div className='navbar-logo-cont'>
          <img
            loading='lazy'
            className="navbar-logo"
            src="https://fica-data.s3.ap-south-1.amazonaws.com/img/logo-without-text.png"
            alt="Logo"
            onClick={() => navigate('/')}
          />
          <img
            loading='lazy'
            className="text-logo"
            src="https://fica-data.s3.ap-south-1.amazonaws.com/img/logo-text.png"
            alt="Logo"
            onClick={() => navigate('/')}
          />
        </div>
        <ul className='nav-links'>
          {navLinks.map((link) => (
            <li
              key={link.id}
              // className={activeLink === link.id ? 'active' : ''}
              // style={{ backgroundColor: activeLink === link.id ? 'black' : 'transparent' }}
            >
              {link.dropdown ? (
                <Dropdown overlay={renderMenu(link.dropdown, link.id)} trigger={['hover']} onVisibleChange={e => setDropDownFlag({ ...dropDownFlag, [link.id]: e })}>
                  <div>
                    <Space>
                      {link.title}
                      <DownOutlined key={link.id} style={{ color: '#403F3F', rotate: `${dropDownFlag[link.id] ? '180deg' : '0deg'}`, transition: 'all linear 0.2s' }} />
                    </Space>
                  </div>
                </Dropdown>
              ) : (
                <div onClick={() => handleLinkClick(link)}>{link.title}</div>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <Drawer
        title="Menu"
        placement="left"
        onClose={onClose}
        open={drawerOpen}
        width="60%"
      >
        <ul className='drawer-menu'>
          {navLinks.map((link) => (
            <li
              key={link.id}
              // className={activeLink === link.id ? 'active' : ''}
              // style={{ backgroundColor: activeLink === link.id ? 'black' : 'transparent' }}
            >
              {link.dropdown ? (
                <Dropdown overlay={renderMenu(link.dropdown, link.id)} trigger={['click']} onVisibleChange={e => setDropDownFlag({ ...dropDownFlag, [link.id]: e })}>
                  <div>
                    <Space>
                      {link.title}
                      <DownOutlined key={link.id} style={{ color: '#403F3F', rotate: `${dropDownFlag[link.id] ? '180deg' : '0deg'}`, transition: 'all linear 0.2s' }} />
                    </Space>
                  </div>
                </Dropdown>
              ) : (
                <div onClick={() => handleLinkClick(link)}>{link.title}</div>
              )}
            </li>
          ))}
          <div className='headerLinks'>
            {headerLinks.map((link) => (
              <li
                key={link.id}
                // className={`header-links ${activeLink === link.id ? 'active' : ''}`}
                // style={{ backgroundColor: activeLink === link.id ? 'black' : 'transparent' }}
              >
                <div onClick={() => handleClick(link.id)}>{link.title}</div>
              </li>
            ))}
          </div>
        </ul>
      </Drawer>
    </div>
  );
};

export default NavBar;
