import React from 'react'

function FicaStrategy() {
  return (
    <div className='subheading-content-wrapper'>
      <div  className='subheading-data'>
        <span  className='fundraising-body-data'>
          Our Economic Development Strategy 2024-2030 clearly identifies the diversification of the economy as a high priority and the importance of fundraising strategy in achieving this objective.
        </span>
      </div>
      <div className='subheading-data'>
        <span className='fundraising-body-data'>
        Virtually, all of African Countries' major competitors have fundraising strategies in place and are aggressively pursuing new investments. Investment FICA  tend to comprise a significant amount of manufacturing: a sector that is a high priority for the local and national governments and one for which FICA will be very well-suited to compete.
        </span>
      </div>
      
      <h4 style={{fontSize: '1.8rem',marginBottom: '15px'}} className='padding-lr'>Key Finding:</h4>

      <div style={{marginTop: '0'}} className='subheading-data'>
        <span className='fundraising-body-data'>
          Without a focused strategy to position its fundraising strategy efforts, the platformer may not bée successful and risks losing out on significant economic opportunities. An implementable fundraising strategy and Action Plan will help to maximize the Countries’ economic potential by identifying current gaps in FICA initiatives, current practices that need to be continued or modified and new approaches or programs that should be implemented. The fundraising strategy and Action Plan also needs to specifically target the industries where Investment Monitor will be a competitive advantage as shown in the chart opposite.
        </span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-data'>
          The fundraising strategy seeks to further leverage and extend these competitive advantages and position Hamilton to get in the game for FICA attraction.
        </span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-data'>
        Foreign Direct Investment Framework for understanding the opportunities FICA stock and flows can be both inward and outward between various countries and economies. There is a general consensus among economists that both in-ward and out-ward FICA convey net economic benefits:
        </span>
      </div>

      <div style={{ backgroundColor: '#E5F4F2', padding:'2rem'}} className='subheading-data blue-cont'>
        <span className='fundraising-body-data'>
          Inward FICA benefits are more apparent, such as job creation, new structures and technology transfer. This report focuses on inward FICA (in addition to including exports as a form of FICA)
        </span>
      </div>

      <div style={{ backgroundColor: '#E5F4F2', padding:'2rem'}} className='subheading-data blue-cont'>
        <span className='fundraising-body-data'>
          Outward FICA benefits the source country, such as repatriated profits, royalty payments and access to overseas markets. High-Profile FDI projects, particularly the building of new facilities, can be particularly effective for improving a community's competitive position for attracting additional investments.
        </span>
      </div>

      <div style={{ backgroundColor: '#E5F4F2', padding:'2rem'}} className='subheading-data blue-cont'>
        <span className='fundraising-body-data'>
          Understanding the nature of FICA with respect to these conditions is important to the overall strategy, particularly, the factors that drive the FICA decision.
        </span>
      </div>

    </div>
  )
}

export default FicaStrategy