import React, { useEffect, useState } from "react";
import GoaplGirish from "./GopalGirish";
import JeanBosco from "./JeanBosco";
import DailyRamasia from "./DailyRamasia";
import PhilipUzuma from "./PhilipUzuma";
import Profiles from "../landingPage/Profiles";
import { teamProfile } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";

const TeamProfiles = () => {

    const [id, setID] = useState('')

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const url = location.pathname
        const parts = url.split('/')
        const newId = parts[parts.length - 1]
        setID(newId)
        scorllToTop()
    }, [location.pathname])

    useEffect(() => {
        scorllToTop()
        if (!id.startsWith('Gopal-Ghirish') && !id.startsWith('Jean-Bosco-CHENDJOU') && !id.startsWith('Daily-Ramasia') && !id.startsWith('Mr.-Philip-UZUMA-NNGANYADI') && id !== '') {
            navigate('/not-found')
        }
    }, [id])

    const scorllToTop = () => {
      window.scrollTo({
          top : 0,
          behavior : "smooth"
      })
    }

    return (
        <>
        {id!=='' && (
            <>
            {id.startsWith('Gopal-Ghirish') && <GoaplGirish/>}
            {id.startsWith('Jean-Bosco-CHENDJOU') && <JeanBosco/>}
            {id.startsWith('Daily-Ramasia') && <DailyRamasia/>}
            {id.startsWith('Mr.-Philip-UZUMA-NNGANYADI') && <PhilipUzuma/>}
            </>
        )}
        <Profiles title='Team Profile F.I.C.A.' data={teamProfile}/>
        </>
    )
}

export default TeamProfiles