import React, { useEffect, useState } from "react";
import { BoardMemberData, boardMembers } from "../../constants";
import Profiles from "../landingPage/Profiles";
import { useNavigate } from "react-router-dom";

const BoardTemMember = () => {

    const navigate = useNavigate()

    const [id, setID] = useState('');
    const [data, setData] = useState({
        id: '',
        title: '',
        image: '',
        oneLiner: [],
    });

    useEffect(() => {
        let url = window.location.pathname;
        let parts = url.split('/');
        setID(parts[parts.length - 1]);
        scorllToTop()
    }, [window.location.pathname]);

    useEffect(() => {
        let memberData = BoardMemberData.find(member => member.id === id);
        if (memberData) {
            setData({
                id: memberData.id,
                title: memberData.title,
                image: memberData.image,
                oneLiner: memberData.oneLiner,
            });
        } else if (id && id !== 'HM-MAFO-TICHA-POUOKAM-MPELE-IV' && !memberData) {
            navigate('/not-found')
        }
    }, [id]);

    useEffect(() => {
        scorllToTop()
    }, [])
    
    const scorllToTop = () => {
        window.scrollTo({
            top : 0,
            behavior : "smooth"
        })
    }

    const handleDownloadProflie = () => {
        const link = document.createElement('a')
        link.href = `https://fica-data.s3.ap-south-1.amazonaws.com/pdfs/HM-MAFO-TICHA-POUOKAM-MPELE-IV.pdf`
        link.download = 'HM-MAFO-TICHA-POUOKAM-MPELE-IV.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <>
            {(id !== 'HM-MAFO-TICHA-POUOKAM-MPELE-IV' && id !== '') && (
                <>
                    <div className="board-member-container">
                        <div className="cirlce-bg">
                            <div className="board-image-container">
                                <img src={`${data.image}`} alt="" className={`board-image ${data.id==='Mr.-Desmopnd-TAY' ? 'desmopnd-ray' : data.id==='Mr.-Chakib-Zhouaghi' ? 'chakib-zhouagi' : id==='Mr.-Badir-AL-MUSHREKH' ? 'badir-al' : id==='Mr.-Allen-WU' ? 'allen-wu' : id==='Mrs.-Lety-Eposi-Endeley' ? 'lety-eposi' : ''}`} style={{ left: `${data.id === 'Mr.-Chakib-Zhouaghi' && '0'}` }} />
                            </div>
                        </div>
                        <h2 className="member-title">{data.id.replace(/-/g, ' ')}</h2>
                        <h3 className="designation">{data.title.split('\n').map(text => (
                            <>{text}<br /></>
                        ))}
                        </h3>
                        <div>
                            {data.oneLiner.map((line, idx) => (
                                <>
                                    <p className="member-content" key={idx}>
                                        {line.split('\n').map(text => (
                                            <>{text}<br /></>
                                        ))}
                                    </p>
                                    <br />
                                </>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {(id === 'HM-MAFO-TICHA-POUOKAM-MPELE-IV' && id !== '') && (
                <div id="board-hm-ticha-page">
                    <div className="board-member-container">
                        <div className="cirlce-bg">
                            <div className="board-image-container">
                                <img src={`https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/hm_mafo.jpeg`} alt="" className="board-image hm-mafo" />
                            </div>
                        </div>
                        <h2 className="member-title">HM MAFO TICHA POUOKAM MPELE IV</h2>
                        <h3 className="designation">President and Founder of F.I.C.A.</h3>
                        <div className="member-body-container">
                            <h3 className="heading">Biography-</h3>
                            <p className="member-content">Her Majesty is an International Expert on Financial Good Governance.</p>
                            <br />

                            <h3 className="heading">Current Position</h3>
                            <p className="member-content"><strong>President of FICA</strong> International Federation of Consulat Chambers for Africa focus on Economy Diplomacy</p><br />
                            <p className="member-content"><strong>The Advisory Board of the AFCFTA</strong> African Continental Free Trade Areas focus mostly on Pilar (7) of the Agreement Trade Facilitation (data's information protection, finance and protection and cross border Trade corridors): <a href="https://www.afcfta.au.int">www.afcfta.au.int</a></p><br />
                            <p className="member-content"><strong>Consultant Expert for Investment</strong> Boutik Firme <strong>INTA CAPITAL SUISSE</strong> Focus on issuing collateral instruments for Investment projects like back garantie, credit l'une and others : <a href="https://www.intacapitalsuisse.ch">www.intacapitalsuisse.ch</a></p><br />
                            <p className="member-content"><strong>Strategic Partner</strong> for the <strong>ANNUAL INVESTMENT</strong> for the last 10 years: <a href="https://www.aimcongress.com">www.aimcongress.com</a></p><br />
                            <p className="member-content"><strong>Ambassador of FAGACE</strong> African Guarantee Fund for Economic Cooperation : <a href="https://www.le-fagace.org">www.le-fagace.org</a></p><br />
                            <p className="member-content"><strong>Ambassador or UECE</strong> European Union Body Expert focus on International Development on Knowledge Transfer : <a href="https://www.euce.eu">www.euce.eu</a></p><br />

                            <h3 className="heading">Areas of Expertise</h3>
                            <p className="member-content">
                                <strong>H.M MAFO TICHA MPELE VI</strong> studied diplomacy and strategy at CEDS Paris; Graduated from the Military Academy and the War College of Paris. She worked for 6 years in various European organizations as an Expert 
                                in charge of economic missions. For the last 8 years, she has acted as an expert in good financial governance, development policy, management and planning of sustainable projects. It brings its expertise 
                                in the preparation, monitoring and evaluation of good financial governance interventions (projects, sector budget support programs), initially oriented towards South-South and triangular cooperation.
                            </p>
                            <br />

                            <h3 className="heading">Intervention covers:</h3>
                            <p className="member-content">
                                1. Formulation, promotion and quality control as project manager within project teams.
                            </p><br />
                            <p className="member-content">
                                2. The guarantee of the development of these formulations in accordance with the methods, standards, concepts and "appropriate good practices", is taking into account the international orientation and the sectoral policy adopted by the requesting country or third country.
                            </p><br />
                            <p className="member-content">
                                3. Collecting and mobilizing funds for both technical resources and for capacity building and appropriate training.
                            </p><br />
                            <p className="member-content">
                                4. The definition of the concrete scope of the project with regard to the eligibility criteria in terms of blending finance and/or equity finance.
                            </p><br />
                            <p className="member-content">
                                5. Training programming: design and resilience report in sustainable development.
                            </p><br />
                            <p className="member-content">
                                6. The design and preparation of all financial documents: pre-financing, mixed financing, fundraising, equity finance, mezzanine financing, debt finance, trade finance and guarantee guarantees on financing.
                            </p><br />

                            <h3 className="heading">Professional Career</h3>
                            <p className="member-content">
                                <strong>Since 2014 President of FICA</strong> (International Federation of Consular Chambers of Africa) Evaluation expertise: Capacity building; Master Plan (Agro-Industrial Industrials Parks); Feasibility studies and social impact analysis Example of a project: Development of a milk processing project in a rural area (national region of Oromia in Ethiopia) mobilization of resources for the financing of the project: lobbying with international donors (European Commission, World Bank and Private Equity).
                            </p><br />
                            <p className="member-content">
                                <strong>2015-2018 : Project Management 2020</strong> (EU Funds): Optimize performance and transparency in the management and use of funds; Anticipating and responding to the challenge of European Rules To developing countries. various countries in Africa.
                            </p><br />
                            <p className="member-content">
                                <strong>2014-2020</strong> Expert/Agent for the procurement mobilization external AID (European Development Fund and International Cooperation Instrument)-KPMG(France):<a href="https://www.kpmg.fr">www.kpmg.fr</a> 
                            </p><br />
                            <p className="member-content">
                                <strong>2013-2016:</strong> Project Advisor on Conception Resilience Reports in Sustainable Developments' (Singapore Cooperation Enterprise)Singapore :<a href="https://www.sec.gov.sg">www.sec.gov.sg</a>
                            </p><br />
                            <p className="member-content">
                                <strong>2013-2018 :</strong> Expert Project for UNIDO Reinforcement of Capacity Building; Masterplan Ning (Agribusiness Industrial Parks) Federal Democratic Republic of Ethiopia 
                            </p><br />
                            <p className="member-content">
                                <strong>2012-2013.</strong> CEO FEBEC (France Export Business Economy & Cooperation) Aquitaine (Fr) 2010-2012. Economic Advisor to the CCI of Pau France 2008-2012 Director of a Communication Agency (Paris) 2006-2008 Economic Advisor City of Levalloisian Perret (Fr) 2003-2006 Head of communication City of Neuilly S/Seine (France).
                            </p><br />
                            <p className="member-content">
                                2012-2013. CEO FEBEC (France Export Business Economy & Cooperation) Aquitaine (Fr)
                            </p><br />
                            <p className="member-content">
                                2010-2012. Economic Advisor to the CCI of Pau France 
                            </p><br />
                            <p className="member-content">
                                2008-2012 Director of a Communication Agency (Paris)
                            </p><br />
                            <p className="member-content">
                                2006-2008 Economic Advisor City of Levalloisian Perret (Fr)
                            </p><br />
                            <p className="member-content">
                                2003-2006 Head of communication City of Neuilly S/Seine (France).
                            </p><br />

                            <h3 className="heading">Academic Background</h3>
                            <p className="member-content">Master II Diplomacy & Strategy CEDS Center for Diplomatic and Strategic Studies (Paris 08) and War School of Paris 07</p><br />
                            <p className="member-content">
                                <strong>Diplomacy:</strong><br />
                                Diplomatic protocol; diplomatic speech; economic diplomacy; Diplomatic accreditation; "Tracts to diplomacy"; bilateral agreements; the Protocol to the United Nations, Organizations of Nations. United Nations organizations. 
                                The Charter of the United Nations; United Nations system; multilateral agreements; UN negotiations; UN subsidiary bodies (role and functioning); Conventions and Treaties UN Troops, Consolidation of Democracy. The European 
                                Union the European Union and its various institutions: the European Council, the European Commission, the European Parliament (role and functioning); Origin & creation; the Economic Community of Europe; Geopolitics and 
                                demographic Europe; European Union accession process (PSA, ASA, IPA), European Defense (FRONTEX, EES, European Space Agency). International Law: International Arbitration; commercial agreements ; International Court of 
                                Justice; International Criminal Law, Criteria for International Sanctions, Civil Jurisdiction, International Treaties, Special Courts, Judicial Procedures (inquisitorial or adversarial tradition); Dispute-State: 
                                Acknowledgment and Reference. Humanitarian organizations (NGOs) Humanitarian organizations: situation of contexts, challenges in wars, interventions linked to armed conflicts, deployment of aid, mandates of NGOs 
                                (global, operational or political)
                            </p><br />
                            <p className="member-content">
                                <strong>Strategy:</strong><br />
                                Issues of geopolitics, operational strategy, the new economic war, globalization, New Exchequer Politics, Asymmetric Wars, systemic analysis of the great powers, The Collateral War, world war, the operational role of 
                                the United Nations Security Council United Nations, NATO Policies, NAVI Operational Strategy US and French Military Bases Abroad: Their Role and Works for example in (Africa).
                            </p><br />
                            <p className="member-content">
                                <strong>Defense Policy:</strong><br />
                                Insurgent Warfare; advantages and disadvantages, disarmament and arms control; operational planning method; Generic process Causes of conflict: real drivers and political outcomes at the end of conflicts; Intelligence 
                                in wartime: crisis and conflict management, post-conflict management; the operational chain of command, global operational approach;
                            </p><br />
                            <p className="member-content">
                                <strong>Coalition Exercise:</strong> Practice Exercises and Lectures: MAB Mission Analysis Brief SPD Strategic Planning Directive OPD Operational Planning Directive DB Decision Brief COA Concept of Operation SOR Analysis CPOE Requirement Status 
                                Full Environment Readiness operational
                            </p><br />
                            <p className="member-content">
                                <strong>Master II European Organizations CIFE -</strong> International Center for European Training (Nice) 
                            </p><br />
                            <p className="member-content">
                            New partnership policy of the European Union, the European Parliament, its role, the executive power of the European Commission, of the Member States of the European Union, foreign policy of the European Union, 
                            the financing of foreign aid and cooperation in the European Union and the funding program for the SDGs (Sustainable Development Goals).
                            </p><br />
                            <p className="member-content">
                                <strong>Master Degree</strong> Public Relations/Image Management University of Nantes (France)
                            </p><br />
                            <p className="member-content">
                                <strong>Licenses I</strong> in Information Science and Technology (Paris Sorbonne) 
                            </p><br />
                            <p className="member-content">
                                <strong>HOBBIES</strong> Literature, Spirituality, Music and Travel
                            </p><br />
                        </div>
                    </div>
                    <div className="board-footer-banner">
                        <img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/QR-code.png" alt="" className="qr-code" />
                        <div className="banner-content">
                            <p className="banner-text">Current address: Residence Sarakawa Lomé Republic of Togo</p>
                            <a href="tel: (+228) 92 48 30 94" className="banner-text">Tel. (+228) 92 48 30 94</a>
                            <a href="mailTo: ticha.virginie@gmail.com" className="banner-text">Email: ticha.virginie@gmail.com</a>
                        </div>
                        <div className="download-profile" onClick={handleDownloadProflie}>
                            <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/download-icon.png" alt="" className="download-icon" />
                            <p className="download-text">Download Profile</p>
                        </div>
                    </div>
                </div>
            )}
            <Profiles title='Board Team Members F.I.C.A.' data={boardMembers} />
        </>
    );
}

export default BoardTemMember;
