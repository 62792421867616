import React, { useEffect } from "react";
import { Select } from "antd";
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from "react-router-dom";

const AboutUs = () => {

    const navigate = useNavigate()

    useEffect(() => {
        scorllToTop()
    }, [])

    const handleRedirect = (link, id) => {
        navigate(link, {state: {pageNo: id}})
    }
    
    const scorllToTop = () => {
        window.scrollTo({
            top : 0,
            behavior : "smooth"
        })
    }

    return (
        <>
        <div className="about-us-image-container">
            <img loading="lazy" src='https://fica-data.s3.ap-south-1.amazonaws.com/img/about/about-us-hero-image.png' alt="" className="about-us-image"/>
            <Select 
                className="about-drop-down" 
                suffixIcon={<CaretDownOutlined style={{color:'#000'}}/>}
                placeholder='F.I.C.A Context'
                value={"F.I.C.A Context"}
                onChange={(e) => handleRedirect('/about', e)}
            >
                <Select.Option value="1" >FICA Engagement</Select.Option>
                <Select.Option value="2" >FICA Partners</Select.Option>
                <Select.Option value="3" >Our Institution</Select.Option>
                <Select.Option value="4" >Background and problem analysis</Select.Option>
                <Select.Option value="5" >ACTIONS PLAN</Select.Option>
                <Select.Option value="6" >ECCSA</Select.Option>
            </Select>
        </div>
        <div className="about-us-container">
            <h3 className="about-us-heading">About F.I.C.A.</h3>
            <p className="about-us-text">
                FICA is an International Organization working to preserve Humanity, climate change, food security, health and welfare, capacity 
                building in technical training, and disclosure of economic ecosystems.
                <br/><br/>
                Environmental protection, and development of inclusive & sustainable infrastructure & we therefore put our respective skills 
                to work for economic and social development in Africa.
            </p>
            <div className="about-us-section">
                <div>
                    <h3 className="about-us-heading">The strategy orientation of FICA</h3>
                    <p className="about-us-text">
                        Promote inclusive & sustainable industrial development in Africa, Propose & follow up on quality and secure investment in 
                        Africa. Contribute to making the markets more accessible to medium & small-size entrepreneurs. Foster the transfer of skills 
                        in areas such as technical, project management, procurement & negotiation, Operational/manufacturing, and financial/economic.
                    </p>
                </div>
                <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/about/about-us-section-image.png" alt="" className="about-us-sec-img"/>
            </div>
            <h3 className="about-us-heading">Target Focus</h3>
            <p className="about-us-text">
                A target approach linked to Good Governance. The concept of governance was initially developed in the commercial sector, where the main 
                aims were to prevent conflicts between shareholders and managers of a company.
                <br/><br/>
                The objective is to make managers act in the interests of shareholders and not in their interests. Codes of conduct have been developed 
                for commercial companies, (Buysse code for SMEs).
            </p>
        </div>
        <div className="footer-img-container"><img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/about/institutional/targetFocus.png" alt="" className="footer-image" /></div>
        <div className="about-us-container">
            <h3 className="about-us-heading">Smarter approach</h3>
            <p className="about-us-text footer-text">
                Promote inclusive & sustainable industrial development in Africa.<br/>
                Propose & follow-up on a quality and secure investment in Africa.<br/>
                Mauris turpis augue, facilisis rutrum diam pellentesque
            </p>
            <Link to='/about' >
                <button className="learn-more-btn">Learn More</button>
            </Link>
        </div>
        </>
    )
}

export default AboutUs