import React, { useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Engagement, Partners, Institution, BackgroundAndProblem, ActionPlan, ECCSA } from '../components';
import {MenuOutlined} from '@ant-design/icons'
import { Dropdown } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

const AboutFICA = () => {
  const [selectedKey, setSelectedKey] = useState('1');

  const location = useLocation()
  const navigate = useNavigate()

  const handleClick = (key) => {
    setSelectedKey(key);
    navigate('/about', {state: {pageNo: key}})
  };

  useEffect(() => {
    scorllToTop()
  }, [])

  useEffect(() => {
    if(location.state && location.state.pageNo) {
      setSelectedKey(location.state.pageNo)
    }
  }, [location.search])

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  const menuItems = [
    { key: '1', label: 'FICA Engagement', content: 'F.I.C.A. Engagement', component: <Engagement instituional={false}/> },
    { key: '2', label: 'FICA Partners', content: 'F.I.C.A. Partners', component: <Partners /> },
    { key: '3', label: 'Our Institution', content: 'Our Institution', component: <Institution /> },
    { key: '4', label: 'Background and problem analysis', content: 'Background and problem analysis', component: <BackgroundAndProblem /> },
    { key: '5', label: 'ACTIONS PLAN', content: 'Action Plans', component: <ActionPlan /> },
    { key: '6', label: 'ECCSA', content: 'ECCSA', component: <ECCSA /> },
  ];

  return (
    <>
      <div className='aboutfica-container'>
        <div className="aboutfica-menu-container">
          <div className="custom-menu">
            {menuItems.map(item => (
              <div
                key={item.key}
                className={`menu-item ${selectedKey === item.key ? 'active' : ''}`}
                onClick={() => handleClick(item.key)}
              >
                <span>{item.label}</span>
                <AiOutlineRight className="menu-icon" />
              </div>
            ))}
          </div>
        </div>
        <div className="about-banner">
          <div className="about-banner-image" style={{backgroundImage: 'url("https://fica-data.s3.ap-south-1.amazonaws.com/img/banner.jpeg")'}}>
            <Dropdown
              menu={{
                items: menuItems,
                selectable: true,
                selectedKeys: selectedKey,
                onClick: (e) => handleClick(e.key),
              }}
            >
              <MenuOutlined className='menu-icon-sm' style={{color:'#FFFFFF'}} />
            </Dropdown>
            <div className="image-text">
              {menuItems.map(item => (
                selectedKey === item.key && <h1 key={item.key}>{item.content}</h1>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="aboutfica-content-wrapper">
        {menuItems.map(item => (
          selectedKey === item.key && (
            <div key={item.key}>
              <h1 style={{padding:'0 4rem'}}>{item.content}</h1>
              {item.component}
            </div>
          )
        ))}
      </div>  
    </>
  );
};

export default AboutFICA;