import React from 'react';
import { aboutPartners } from '../../constants';

function Partners() {
    const ficaPartner = aboutPartners.find(partner => partner.title === 'FICA Partners')?.images || [];
    const developmentPartner = aboutPartners.find(partner => partner.title === 'Development Partners')?.images || [];

    return (
        <>
        <div className='padding-4'>  
            <div className='about-partner-container'>
                {ficaPartner.map(image => (
                    <img loading='lazy' key={image.id} src={image.image} alt={`Partner ${image.id}`} />
                ))}
            </div>
            <div style={{ paddingLeft: '0' }} className='line'></div>
            <h1>Development Partners</h1>
            <div className='about-partner-container development'>
                {developmentPartner.map(image => (
                    <img loading='lazy' key={image.id} src={image.image} alt={`Partner ${image.id}`} />
                ))}
            </div>
        </div>
        <div style={{backgroundColor: '#E9F6FD', padding: '4rem', marginTop: '2rem'}} className='padding-4'>
            <p style={{fontWeight: '700'}}><i>Private sector, Cooperatives, Federations, Consular Chambers of Commerce and industry and Civil Society Organizations (CSOs) in Ethiopia and in other African countries FICA Activities.</i></p>
        </div>
        </>
    );
}

export default Partners;