import React from 'react'

function GovtBenifit() {
  return (
    <div className='subheading-content-wrapper'>
      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>1</span>
        <span className='fundraising-body-data'>
          Capital formation maximizes economic potential within the local economy including more jobs, improvements in productivity and economic growth evidenced in GDP gains. Job creation within the local economy enhances the skills and capability domestically, thereby contributing to further growth and opportunity.
        </span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>2</span>
        <span className='fundraising-body-data'>Increased tax revenues are a result of additional businesses and jobs that deliver corporate taxes, income taxes, property taxes and a wide range of additional tax benefits to the local economy.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>3</span>
        <span className='fundraising-body-data'>Innovation and technology benefits enhance the host country/economy as new ideas, processes and techniques are introduced domestically, again enhancing the long-term potential.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>4</span>
        <span className='fundraising-body-data'>TDiversification within the local economy again helps to strengthen and build upon a new business and economic enterprise, and deliver additional strength to the economic foundation</span>      
      </div>

    </div>
  )
}

export default GovtBenifit