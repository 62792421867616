import React from 'react';
import { Routes, Route } from "react-router-dom";
import { NavBar, HeaderNavBar, Footer } from './components';
import { LandingPage, AboutFICA, OurGoal, Insights, FicaSolution, Help } from './layout';
import BoardTemMember from './components/BoardTeamMember';
import TeamProfiles from './components/TeamProfiles';
import AboutUs from './components/AboutUs';
import NotFound from './components/NotFound';
import ThankYou from './components/Thankyou';
import './sass/main.scss';

function App() {
  return (
    <div>
      <NavBar />
      <HeaderNavBar />  
      <div className='main-container'>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/about" element={<AboutFICA />} />
          <Route exact path="/about-fica" element={<AboutUs />} />
          <Route exact path="/goals" element={<OurGoal />} />
          <Route exact path="/insights/:id" element={<Insights />} />
          <Route exact path="/solutions" element={<FicaSolution />} />
          <Route exact path='/team-profile/:id' element={<TeamProfiles/>}/>
          <Route exact path='/board-team-member/:id' element={<BoardTemMember/>} />
          <Route exact path='/help' element={<Help/>} />
          <Route exact path='/thank-you' element={<ThankYou/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
