import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

const GoaplGirish = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [pageNo, setPageNo] = useState(0)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const pageNo = queryParams.get('pageNo') || 0
        if(!pageNo || Number(pageNo)>3 || Number(pageNo)<1 || isNaN(Number(pageNo))) {
            navigate('/not-found')
        }
        setPageNo(Number(pageNo))
        scorllToTop()
    }, [location.search])

    const handleBack = () => {
        if (pageNo > 1) {
            const newPageNo = pageNo - 1
            setPageNo(newPageNo)
            navigate(`/team-profile/Gopal-Ghirish?pageNo=${newPageNo}`)
        }
    }

    const handleNext = () => {
        if (pageNo < 3) {
            const newPageNo = pageNo + 1
            setPageNo(newPageNo)
            navigate(`/team-profile/Gopal-Ghirish?pageNo=${newPageNo}`)
        }
    }

    const scorllToTop = () => {
        window.scrollTo({
            top : 0,
            behavior : "smooth"
        })
    }

    const handleDownloadProflie = () => {
        const link = document.createElement('a')
        link.href = `https://fica-data.s3.ap-south-1.amazonaws.com/pdfs/Mr.-Ghirish-GOPAL.pdf`
        link.download = 'Mr.-Ghirish-GOPAL.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div id="gopal-girish-page-download">
        {pageNo!==0 && (
        <>
        <div className="team-profile-hero-container">
            <div className="cirlce-bg">
                <div className="hero-image">
                    <img src={`https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/gopalGhirish.jpeg`} alt="" className="team-hero-img gopal-girish" style={{top:'0%', left:'-10%', height:'450px'}}/>
                </div>
            </div>
            <h2 className="title">Mr. Ghirish GOPAL</h2>
            <h3 className="designation" style={{margin:'1rem 0'}}>Consultant</h3>
            <h4 style={{margin:'0', fontSize:'1.25rem', fontWeight:'600'}}>27th May 1981</h4>
        </div>

        {pageNo===1 && (
        <>
        <div className="team-profile-body-container">
            <Divider className="label-heading" orientation="left" orientationMargin={0}>Work Experience</Divider>
            <h3 className="label-sub-heading">Smart Matter Expert and Advisor with the Ministry of Finance, Republic of Mauritius</h3>
            <p className="label-text light">
                I was called in December 2022 to join full time the cabinet of the Ministry of Finance to advise the chamber about new financial projects and upcoming financial instruments. 
                My connection with the government has been ongoing since 2010 when I was working in the Global Business segment. My international exposure and interest in modern financial 
                products have always been appraised by the surrounding people. 
                <br/><br/>
                We have created a financial ecosystem in Mauritius that can face the demands of our International Trade,Import and Export Protocols, Macro Economic transactions and commitments 
                to deliver the best banking support when it comes to Mauritius as a major hub to Africa. With vast exposure in banking, I was part of the Smart matter Expert team that travelled 
                with several with honorary members to different part of the World when it comes to the prestige of our country and its sound economy.
            </p>
            <p className="label-text light" style={{margin:'1.75rem 0'}}>
                <strong>A few realizations that were made possible:</strong>
            </p>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    $ 1.7 billion from India for the Metro Express Project in Mauritius under a Grant
                </li>
                <li className="bullet-item">
                    Suppressing the risks of our Mauritius image to get off the Black List of OECD in 2021, workshop battled in  New Delhi, India
                </li>
                <li className="bullet-item">
                    Setting up of the first Tokenisation Platform in Mauritius where any foreign company can come and trade on  an international digital platform their equities in terms of tokens
                </li>
                <li className="bullet-item">
                    Setting up of a Digital Banking model that uses the Payment Intermediary Services License, capable of  effecting bank transfers on behalf of clients
                </li>
                <li className="bullet-item">
                    Adoption of the API model within the Central Bank of Mauritius (ongoing bids for commercial service  provider)
                </li>
            </ul>
            <h3 className="label-sub-heading" style={{margin:'3rem 0'}}>Director at Blue Ocean Management Ltd – (Now known as StraFin Management Services Ltd)  From November 2017 to November 2022</h3>
        </div>
        <div className="blue-container">
            <p className="label-text">
                I joined Blue Ocean Management Ltd as Marketing Director in November 2017. The approach was more about bringing my experience, expertise and network to enable expansion and growth since 
                its inception in September 2010, where I was an offshore consultant to the management company.
                <br/><br/>
                During my tenure as Managing Director, I encouraged the Board to tie up with several 
                international players, signedagreements with Morison Dubai, KSI Africa, Law Firms in Cyprus, Consultants in Singapore, Hong Kong and Seychelles.
                <br/><br/>
                We had reached 200 plus clients in 2019 
                and our great achievement was the onboarding of 2 special licenses post COVID-19 period, in November 2021, an Asset Management Company with an AUM of Euro 100 m and afinancial distribution 
                company, structuring financial products and distributing them to the high net worth clients.
                <br/><br/>
                I oversee and manage a team of 17 comprising senior managers, officers and accountants, covering 
                the day to day running of an offshore management company. My personal rapport with bankers, regulators and international players have always been an adding value to the business.
                <br/><br/>
                I resigned on the 22nd of November 2022 as there had been a drastic change in shareholding and management board.
            </p>
        </div>
        <div className="team-profile-body-container">
            <h3 className="label-sub-heading black-underline">Freelance Consulting in Global Business Sector within Mauritius, Seychelles and Dubai to bridge India and Africa</h3>
            <p className="label-text light">
                I have been working as an independent consultant from October 2010 till 2017. These seven years spent alone have helped me develop entrepreneurial skills, negotiate with foreign parties, better 
                understanding of the financial services sector and along with consulting, I also maintain relationships with Banking Institutions, Financial Services Commission, Board of Investment, Mauritius 
                Revenue Authority and Companies Division.
                <br/><br/>
                The exposure as Consultant nurture my personality to tackle problems and face life in a very simple way. I have built a strong network of professionals in almost every field of 
                work and always aim to move forward to ensure a sustainable growth in the business model.
                <br/><br/>
            </p>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>Previous Experience</Divider>
            <div className="pink-container">
                <p className="label-text">October 2008 – October 2010 – Full Time (“Bramer” subsidiaries)</p>
                <p className="label-text">October 2007 – September 2008 – 1 Year Management Trainee Program Finance and Marketing</p>
                <p className="label-text">September 2006 – September 2007</p>
                <p className="label-text">September 2003 – August 2006</p>
                <Link to={'/team-profile/Gopal-Ghirish?pageNo=2'} className="read-more">Read More</Link>
            </div>

            <div className="about-more-cont">
                <div style={{width:'100%'}}>
                    <Divider className="label-heading" orientation="left" orientationMargin={0}>About More</Divider>
                    <div className="pink-container">
                        <p className="label-text">Skills and Abilities</p>
                        <p className="label-text">Academic Education</p>
                        <p className="label-text">Technical Trainings</p>
                        <p className="label-text">International Exposure</p>
                        <Link to={'/team-profile/Gopal-Ghirish?pageNo=3'} className="read-more">Read More</Link>
                    </div>
                </div>
                <div>
                    <Divider className="label-heading" orientation="left" orientationMargin={0}>Declaration</Divider>
                    <div className="pink-container">
                        <p className="label-text" style={{fontSize:'1.25rem'}}>I hereby declare that the particulars given above are true and correct to the best of my knowledge and belief. Ghirish Gopal</p>
                    </div>
                    <Divider className="label-heading" orientation="left" orientationMargin={0}>General Information</Divider>
                    <div className="pink-container">
                        <p className="label-text" style={{fontSize:'1.25rem'}}>ID Number- G2705812903316</p>
                        <p className="label-text" style={{fontSize:'1.25rem'}}>Passport Valid: August 2023</p>
                        <p className="label-text" style={{fontSize:'1.25rem'}}>Mobile: (230) 5493 0514</p>
                    </div>
                </div>
            </div>
        </div>
        </>)}

        {pageNo===2 && (
        <>
        <div className="team-profile-body-container">
            <h3 className="label-sub-heading" style={{color:'#D59847'}}>October 2008 – October 2010 – Full Time</h3>
            <p className="label-text light">
                I joined in “Bramer” subsidiaries, member of the BAI Group of Investment as accountant in October 2008, and very quickly promoted to Assistant Finance Manager in March 2009 
                after satisfactory completion of the 6 months probation period. I have been looking at the following entities within the British American Investment Group of Companies 
                (BAI Co Ltd), preparing the accounts, reviewing the statutory filing and coaching the accountant in this day to day workload:
            </p>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    <strong>Bramer Asset Management Ltd</strong>
                </li>
                <li className="bullet-item">
                    <strong>Bramer Asset Management Ltd – Dubai Representative Office</strong>
                </li>
                <li className="bullet-item">
                    <strong>Bramer Securities Ltd</strong>
                </li>
                <li className="bullet-item">
                    <strong>ASMO Securities and Investment Ltd</strong>
                </li>
                <li className="bullet-item">
                    <strong>Prime Capital Management Ltd</strong>
                </li>
                <li className="bullet-item">
                    <strong>Indian Ocean and Privatization Equity Fund – I.O.P.E.F</strong>
                </li>
                <li className="bullet-item">
                    <strong>Bramer Property Fund Limited</strong>
                </li>
            </ul>
            <p className="label-text light">
                I supervise the team, ensure we are in line with all deadlines, and follow-up queries with related regulatory authorities, the Stock Exchange Of Mauritius, Central Depository & 
                Settlement Co. Ltd, Banks and the Financial Services Commission. I am also accountable for running daily requests from all other departments and companies within the Group for 
                Intercompany transactions and Accounts Consolidation at each financial year-end.
                <br/><br/>
                I liaise with foreign clients, fund managers and stockbrokers through Telephone Conversations, Emails, Correspondences, Facsimile etc. I have intensively contributed to the 
                implementation of Oracle E-Business Suite towards the entities under my supervision and designated Super User with special mention of Accounts Receivable Champion for mastering 
                the AR module. 
                <br/><br/>
                In January 2010 I was promoted to Finance/Business Development Manager for Prime Capital Management Ltd, an Offshore Management Company, located in Port Louis. It was a one-man 
                show, doing accounting, administration and marketing for foreign clients. Finally, I have built a team of accounting, Secretary and Corporate Services, and also referred a Chief 
                Operating Officer, a French lady to report directly to the President of the financial arm.
            </p>

            <h3 className="label-sub-heading" style={{color:'#D59847'}}>October 2007 – September 2008 – 1 Year Management Trainee Program Finance and Marketing</h3>
            <p className="label-text light">
                I joined British American Tobacco Ltd on 15 October 2007 as a Management Trainee in finance and Marketing for the Indian Ocean Islands. As part of my induction, I went to Nairobi 
                (BAT Kenya) for three weeks to better understand the Tobacco Industry and as well as getting a feel for the job. I remained in this role for 8 months and travelled to the Indian 
                Ocean Islands for an appreciation of the market. I had exposure of software like SAP, Hyperion Financial Management (HFM) and COG NOS, a very powerful program for Planning and Reporting.
                <br/><br/>
                I had the opportunity in June 2008 to undertake a supervisory role as Demand Planning and Reporting Executive, IOI Clusters in the Marketing department. This position was moving 
                to Kenya as per the structured model and for me, it was a good experience. My hand over was made in Nairobi and my main roles and responsibilities were:
            </p>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Development of statistical models to prepare short and long-term marketing forecasts,
                </li>
                <li className="bullet-item">
                    Assist the Marketing Team in the development of competitive business scenarios and price planning/simulation.
                </li>
                <li className="bullet-item">
                    Maintaining a competitor analysis network and supporting information system to enable scenario modelling
                </li>
                <li className="bullet-item">
                    Providing company marketing staff with appropriate training in the effective use and understanding of marketing research information
                </li>
                <li className="bullet-item">
                    Providing strategic counsel and marketing information to internal marketing clients to meet company business needs.
                </li>
                <li className="bullet-item">
                    Seek to improve the quality of marketing decision-making by analyzing and reporting on the outcomes of all key marketing decisions.
                </li>
                <li className="bullet-item">
                    Continuous analysis of consumer, customer and market trends to identify and recommend new business opportunities to Management team.
                </li>
                <li className="bullet-item">
                    Proactively identify opportunities for performance improvement and work with business owners to solve problem
                </li>
            </ul>

            <h3 className="label-sub-heading" style={{color:'#D59847'}}>September 2006 – September 2007</h3>
            <p className="label-text light">
                I joined Accenture Mauritius Ltd as Transition Team Leader and was assigned to a French Project, The BaxterPharmaceuticals. I was selected to be part of the transition team, shadowing 
                the client in their daily roles. Likewise, I went to Belgium for 6 months, learning and mastering the techniques of the accountants. Furthermore, I got the chance to act as Team Lead 
                in a group of 5 during the assignment abroad. Furthermore, I worked with several Belgian accountants and transferred the knowledge I gained from them with lots of challenges. The Baxter 
                project recruited nearly 125 accounts officers for its several processes of Accounts Payable, Accounts Receivable and General Ledger.
                <br/><br/>
                In May 2007, I was then assigned the Chubb Sicily Project where I was sent to Grenoble, France for AccountsPayable/Billing processes are to be decentralized from Agencies around France 
                and implemented those processes to Mauritius. The task was headed by the Transition Expert and shouldered by a few colleagues and myself as Transition Manager. The billing team was around 
                200 data entry operators.
                <br/><br/>
                In September 2007 Volvo USA was assigned to our team and I worked as Supervisor in the Accounts Receivable Department. The job profile was more flextime and handling of client’s queries 
                with a proper log call system and quality servicing of foreign clients.
            </p>

            <h3 className="label-sub-heading" style={{color:'#D59847'}}>September 2003 – August 2006</h3>
            <p className="label-text light">
                I joined Deutsche Bank Offshore Limited in September 2003 as a Bookkeeper. I went for training in Fiduciary services for twelve months in Guernsey, Channel Island where their main pillar is 
                the financial sector. Furthermore, I acquired experience with some software and information on Offshore, following workshops and presentations on Trustees and Companies and laws governing 
                the Offshore market.
                <br/><br/>
                Once back in Mauritius, I maintain a portfolio of 270 cases, consisting of companies and trusts, dealing in different currencies and hold accounts with several bankers namely Deutsche Bank, 
                HSBC, Goldman Sachs, TISCO, CISCO, EFG Private Bank and Citigroup. I had contact with clients through emails and telephone.
                <br/><br/>
                I kept records of their banking transactions such as Deposits, Loans and Security transactions, Bonds, Insurance, Capital and Revenue Expenditure, and Dividend payments.
            </p>
        </div>
        </>)}

        {pageNo===3 && (
        <>
        <div className="team-profile-body-container">
            <h3 className="label-heading">Skills and Abilities</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    <strong>Up to date with youth trends, Messenger, Linked In, Video Conferencing using Skype</strong>
                </li>
                <li className="bullet-item">
                    <strong>Ease with Information Technology and Information Systems</strong>
                </li>
                <li className="bullet-item">
                    <strong>Accounting packages like QuickBooks, Pavilion Payroll Software, Sicora, SAP and Oracle Business Suite  (AP, AR, GL, FA and CM)</strong>
                </li>
            </ul>

            <h3 className="label-heading">Academic Education</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Certificate of Primary Education (ranked 1,598)
                </li>
                <li className="bullet-item">
                    School Certificate (Aggregate 09)
                </li>
                <li className="bullet-item">
                    Higher School Certificate (Pass with A levels)
                </li>
                <li className="bullet-item">
                    BSc (Hons) Finance (University of Mauritius)
                </li>
                <li className="bullet-item">
                    ACCA – Emile Woolf College London
                </li>
                <li className="bullet-item">
                    MBA – Specialization in Financial Restructuring and Modelling (University of Middlesex)
                </li>
            </ul>

            <h3 className="label-heading">Technical Trainings</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Training in Accounts Clerk by Price Water House Coopers Mauritius, Very Good mentored by Deven Mootoosamy FCCA
                </li>
                <li className="bullet-item">
                    Technical Accounting for Reinsurance by Swiss Africa, attended and certified by Dumsane  Mburugara
                </li>
                <li className="bullet-item">
                    Advanced Trust Planning and Administration
                </li>
                <li className="bullet-item">
                    Real Estate Development, RES, IRS and Property Management by the Board of Mauritius
                </li>
                <li className="bullet-item">
                    Professional Selling by South African Company
                </li>
            </ul>

            <h3 className="label-heading">International Exposure</h3>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">1 year</div>
                    <div className="end-date">Guernsey</div>
                </div>
                <p className="label-text timeline">
                    <strong>(1 year) in Guernsey for Deutsche Bank to build my career in banking and Offshore accounting</strong>
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">8 weeks</div>
                    <div className="end-date">London</div>
                </div>
                <p className="label-text timeline">
                    <strong>In London program by Deutsche Bank to be conversant in Fiduciary Services</strong>
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">6 weeks</div>
                    <div className="end-date">Belgium</div>
                </div>
                <p className="label-text timeline">
                    <strong>In Belgium to be exposed in the Pharmaceutical Industry and French Accounting</strong>
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">2 weeks</div>
                    <div className="end-date">France</div>
                </div>
                <p className="label-text timeline">
                    <strong>In France do a crash course in French Accounting</strong>
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">24 weeks</div>
                    <div className="end-date">Kenya</div>
                </div>
                <p className="label-text timeline">
                    <strong>
                        In Kenya in the Tobacco Business and appointed as Budget Controller and Demand 
                        Planner for Cigarettes within the Indian Ocean Islands India, Turkey, Madagascar, 
                        South Africa, Italy, Dubai & Malaysia (Several visits made from 2010 onwards to 
                        promote me as Financial Consultant for Offshore Businesses)
                    </strong>
                </p>
            </div>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>Declaration</Divider>
            <p className="label-text light">
                <strong>I hereby declare that the particulars given above are true and correct to the best of my knowledge and belief. Ghirish Gopal</strong>
            </p>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>General Information</Divider>
            <p className="label-text light">
                <strong>ID Number- G2705812903316</strong>
            </p>
            <p className="label-text light">
                <strong>Passport Valid: August 2023</strong>
            </p>
            <p className="label-text light">
                <strong>Mobile: (230) 5493 0514</strong>
            </p>
        </div>
        </>)}

        <div className="bottom-btn-container">
            <button className="navigate-button" onClick={handleBack}>Back</button>
            <div className="pages-button-cont">
                <Link to={'/team-profile/Gopal-Ghirish?pageNo=1'} className={`page-btn ${pageNo===1 && 'active-btn'}`}>1</Link>
                <Link to={'/team-profile/Gopal-Ghirish?pageNo=2'} className={`page-btn ${pageNo===2 && 'active-btn'}`}>2</Link>
                <Link to={'/team-profile/Gopal-Ghirish?pageNo=3'} className={`page-btn ${pageNo===3 && 'active-btn'}`}>3</Link>
            </div>
            <button className="navigate-button" onClick={handleNext}>Next</button>
        </div>
        
        <div className="footer-banner">
            <p className="banner-text">Veerasamy Lane, Lapeyrousse Street, Eau-Coulee Curepipe Republic of Mauritius</p>
            <a href="tel: (230) 698 1045" className="banner-text">Tel.-(230) 698 1045</a>
            <a href="mailTo: ghirishgopal@gmail.com" className="banner-text padding-bt-2">ghirishgopal@gmail.com</a>
            <div className="download-profile" onClick={handleDownloadProflie}>
                <img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/download-icon.png" alt="" className="download-icon" />
                <p className="download-text">Download Profile</p>
            </div>
        </div>
        </>)}
        </div>
    )
}

export default GoaplGirish