import React from 'react'

function BackgroundAndProblem() {
  return (
    <>
    <div className='padding-4'>
      <p>
        The 2000s marked the advent of the Millennium Development Goals (MDGs) and 2015 saw the launch of another development agenda with Sustainable Development Goals (SDGs). Many of these development goals are punctuated by positive actions that are to be welcomed and sustained over time. Through the tireless work of many international organizations, with the support of industrialized (OECD) countries and the involvement of the South, and South-South cooperation, several MDGs came to an end with gaps still lingering and this has fed SDGs with unaddressed gaps. The achieved and achieved development agenda remains vast and demand further commitment by all development partners to address the gains and sustain the gains. This requires greater involvement on the part of African countries, primarily concerned with the SDGs and the Agenda 2063 through the development of the appropriate capacity both technical and professional to pave the way for improved socio-economic conditions in Africa through job creation and decent employment.
        <br />“If Africa must guarantee jobs for its 450 million or so school-leavers by 2030, its States must urgently address the mismatch between educational qualifications and the needs of the job markets through massive investments in Critical Technical Skills” Professor Emmanuel Nnadozie, Executive Secretary of The African Capacity Building Foundation (ACBF) at the Africa Talks Jobs' forum held in Addis Ababa — Ethiopia from 30 October to 1 November 2017.
      </p>
    </div>
    <div style={{backgroundColor: '#E9F6FD'}} className='padding-4'>
      <p style={{fontWeight: '500'}}><i>“Africa is the youngest continent of the world, with 60% of its population aged under 25. With “Youth” being the main focus of this year's AU-EU summit we want to inject a youth perspective in the intercontinental dialogue. Their contributions are central, when discussing about a future that belongs to them; Investing in youth is a prerequisite for building a sustainable future” Commissioner Neven Mimica · DG International Cooperation and Development of European Commission</i></p>
    </div>
    <div className='padding-4'>
      <p>
      “The Agenda 2030 is a great achievement because all the countries on our planet have agreed on a common pathway for development. In contrast to the Millennium Development Goals, which defined targets for developing countries, this time all countries — developed and developing nations alike — are part of this Agenda 2030. If there's too much hopelessness in Africa, of course, there will be young people who say they need to find a life elsewhere in the world, By cooperating with those countries we are also creating more security again for ourselves” German Chancellor Angela Merkel
        <br />According to African Development Bank (AfDB)'s Strategy[1] for creating 25 million jobs and equipping 50 million youth from 2016 to 2025, there is an urgent need to promote inclusive economic transformation and jobs-induced growth to improve the quality of life for all Africans. It is indicated in the same Strategy that Africa's youth[2] population is rapidly growing and expected to double to over 830 million by 2050. This population dividend is under-utilized, underemployed, or vulnerably employed and consequently generates little or no income for their livelihoods let alone contribution to national economic growth.
      </p>
    </div>
    </>
  )
}

export default BackgroundAndProblem